import React, { useEffect, useState } from "react";
import "../styles/components/Auth-error-page.css"
import GoogleAds from "../images/google-ads.jpg"


const AuthErrorPage=()=>{
    const redirect_url = localStorage.getItem("redirect_url");

    const [secRemaining, setSecRemaining] = useState(5);
    useEffect(() => {
      setTimeout(() => {
        window.location.assign(redirect_url);
        return;
      }, 4000);
      setInterval(() => {
        setSecRemaining(secRemaining - 1);
      }, 1000);
    });
    return(
        <>
        <div className="Error-page-main-container">
            <div className="Error-page-error-card d-flex-cen">
            <div className="no-data-google-ads-logo-container d-flex-cen">
          <img
            className="google-ads-img"
            src={GoogleAds}
            alt="google ads logo"
          />
          Google Ads
        </div>
                <div className="error-page-primary-text">
                    Sorry your credentials didn't match!
                </div>
                <div className="error-page-secondary-text">
                    Redirecting to <span style={{color:"#1e90ff"}}>{redirect_url.slice(12).slice(0,-1)}</span> in {secRemaining}..
                </div>
            </div>
        </div>
        </>
    )
}

export default AuthErrorPage