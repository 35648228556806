import { createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import GoogleAnalyticsLoginLayout from "../layout/GoogleAnalyticsLoginLayout";
import GoogleLogo from "../images/google-analytics4-logo.png";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f5f6fa",
    },
    secondary: {
      main: "#3c8dbc",
    },
  },
});

    // REACT_APP_GOOGLE_ANALYTICS4_CLIENT_ID_TERCEPT = process.env.REACT_APP_GOOGLE_ANALYTICS4_CLIENT_ID_TERCEPT
    // REACT_APP_GOOGLE_ANALYTICS4_CLIENT_SECRET_TERCEPT = process.env.REACT_APP_GOOGLE_ANALYTICS4_CLIENT_SECRET_TERCEPT

    const REACT_APP_GOOGLE_ANALYTICS4_CLIENT_ID_TERCEPT = "349291241109-olihkv8o64pqa2kq0eh043ot8eu78871.apps.googleusercontent.com"
    const REACT_APP_GOOGLE_ANALYTICS4_CLIENT_SECRET_TERCEPT = "q92Z8yLQBvdgbxhQKVYNxLmX"

const GoogleAnalyticsController = () => {
  const navigate = useNavigate();

  const [showDataTable, setShowDataTable] = useState(false);
  const [Response, setResponse] = useState([]);
  const [access, setAccess] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [showNoData, setShowNoData] = useState(false);
  const [showLayout, setShowLayout] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);

  let url = window.location.href;

  useEffect(() => {
    var urlData = new URL(url);
    var clientcustomerid = urlData.searchParams.get("clientcustomerid");
    var redirect_url = urlData.searchParams.get("redirect_uri");
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const Auth = params.authorization;
    const authenticated = localStorage.getItem("authenticated");
    if(authenticated){
      console.log(authenticated);
    } else if (Auth) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Basic ${Auth}`);
      myHeaders.append("Access-Control-Allow-Origin", "*");
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch("https://connectors.tercept.com/api/v2.3.1/checkauth", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          if (result) {
            if (Auth !== null) {
              localStorage.setItem("auth", Auth);
            }
            if (redirect_url !== null) {
              localStorage.setItem("redirect_url", redirect_url);
            }
            if (clientcustomerid !== null) {
              localStorage.setItem("clientID", clientcustomerid);
              localStorage.setItem("authenticated", true);
            }
            setShowLayout(true);
          }
          if (result === "") {
            navigate("/error");
          }
          console.log(result);
        })
        .catch((error) => {
          navigate("/error");
          console.log("error", error);
        });
    } else{
      navigate("/unauthorized");
    }
  }, []);

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    const clientCustomerId = localStorage.getItem("clientID");
    const redirect_url = localStorage.getItem("redirect_url");

    var urlData = new URL(url);
    var code = urlData.searchParams.get("code");
    console.log(code);

    if (code) {
      setShowSkeleton(true);
      var requestOptions = {
        method: "POST",
      };
      fetch(
        `https://oauth2.googleapis.com/token?client_id=${REACT_APP_GOOGLE_ANALYTICS4_CLIENT_ID_TERCEPT}&client_secret=${REACT_APP_GOOGLE_ANALYTICS4_CLIENT_SECRET_TERCEPT}&code=${code}&grant_type=authorization_code&redirect_uri=https://connectors.tercept.com/googleanalytics/oauthcallback`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.error === "invalid_grant") {
            setShowSkeleton(false);
            navigate(
              `/googleanalytics4?authorization=${auth}&clientcustomerid=${clientCustomerId}&redirect_uri=${redirect_url}`
            );
            return;
          }

          setAccess(result.refresh_token);
          console.log(result.refresh_token);

          var CustomerListHeaders = new Headers();
          CustomerListHeaders.append("Access-Control-Allow-Origin", "*");
          CustomerListHeaders.append("Authorization", `Basic ${auth}`);
          var requestAccessOptions = {
            method: "GET",
            headers: CustomerListHeaders,
            redirect: "follow",
          };
          fetch(
            `https://connectors.tercept.com/api/v2.3.1/googleanalytics4/getPropertyIdlist?refreshToken=${result.refresh_token}`,
            requestAccessOptions
          )
            .then((response) => response.text())
            .then((res) => {
              if (JSON.parse(res).status === 500) {
                setShowNoData(true);
              }

              let result = res
                .substring(1)
                .substring(0, res.length - 2)
                .split(",");
              result.forEach((item, id) => {
                result[id] = {
                  name: item.replaceAll("\"",""),
                  checked: false,
                };
              });
              setResponse(result);
              setShowSkeleton(false);
              setShowDataTable(true);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => console.log("error", error));
    }
  }, [url]);

  const handleGoogleSignIn = async () => {
    window.location.assign(
      `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?access_type=offline&client_id=${REACT_APP_GOOGLE_ANALYTICS4_CLIENT_ID_TERCEPT}&redirect_uri=https://connectors.tercept.com/googleanalytics/oauthcallback&response_type=code&scope=https://www.googleapis.com/auth/analytics.readonly&state=callback&prompt=consent`
    );
  };

  const handleSubmit = async () => {
    const redirect_url = localStorage.getItem("redirect_url");
    const clientCustomerId = localStorage.getItem("clientID");
    const auth = localStorage.getItem("auth");

    let IdList = [];
    let res = Response;
    res = res.filter((val) => val.checked === true);
    res.forEach((ids, index) => {
      return (IdList[index] = ids.name.replaceAll("\"",""));
    });

    var scheduleHeaders = new Headers();
    scheduleHeaders.append("Access-Control-Allow-Origin", "*");
    scheduleHeaders.append("Authorization", `Basic ${auth}`);
    var scheduleRequestOptions = {
      method: "GET",
      headers: scheduleHeaders,
      redirect: "follow",
    };
    fetch(
      `https://connectors.tercept.com/api/v2.3.1/googleanalytics4/schedule?propertyIdList=${IdList.map(
        (val) => val
      )}&clientCustomerId=${clientCustomerId}&refreshToken=${access}`,
      scheduleRequestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setShowSnackBar(true);
        setTimeout(() => {
          window.location.assign(redirect_url);
          return;
        }, 5000);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {true ? (
        <GoogleAnalyticsLoginLayout
          handleGoogleSignIn={handleGoogleSignIn}
          theme={theme}
          GoogleLogo={GoogleLogo}
          handleSubmit={handleSubmit}
          setResponse={setResponse}
          showDataTable={showDataTable}
          access={access}
          Response={Response}
          setCustomerId={setCustomerId}
          customerId={customerId}
          showSkeleton={showSkeleton}
          showNoData={showNoData}
          setShowNoData={setShowNoData}
          setShowDataTable={setShowDataTable}
          showSnackBar={showSnackBar}
          setShowSnackBar={setShowSnackBar}
          setShowSkeleton={setShowSkeleton}
        />
      ) : (
        <div></div>
      )}
    </>
  );
};

export default GoogleAnalyticsController;
