import Checkbox from "@mui/material/Checkbox";
import {Button} from "@mui/material";
import "../styles/components/customer-data-table.css";
import googleAdsLogo from "../images/google-ads.jpg";
import { useState } from "react";

const CustomerDataTable = (props) => {
  const { handleSubmit, setResponse, Response } = props;

  const [enableSave,setEnableSave]=useState(false)
  const handleSelected = (ind, event) => {
    let newArr = Response;
    newArr[ind].checked = event.target.checked;
    setResponse(newArr);
    setEnableSave(Response.some((ele)=>ele.checked===true))
    console.log(enableSave);
  };

  return (
    <>
      <div className="data-table-main-container">
        <div className="google-ads-logo-container d-flex-cen">
          <img
            className="google-ads-logo"
            src={googleAdsLogo}
            alt="google Ads"
          />
          Google Ads
        </div>
        <div className="customer-data-table-main-container">
          <div className="data-table-header">
            <div className="data-table-header-primary d-flex-cen">
              Google Ads Customer ID
            </div>
            <div className="data-table-header-secondary d-flex-cen">Select</div>
          </div>
          {Response.map((res, ind) => {
            return (
              <div key={ind} className="data-table-content-row">
                <div className="data-table-content-primary d-flex-cen">
                  {res.name}
                </div>
                <div className="data-table-content-secondary d-flex-cen">
                  <Checkbox
                    size="samll"
                    onChange={(event) => {
                      handleSelected(ind, event);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div className="Submit-btn-container">
          <Button
            sx={{
              width: "8vw",
              height: "2vw",
              fontSize: "1vw",
              padding: "0vw",
              textTransform: "none",
            }}
            variant="contained"
            onClick={handleSubmit}
            disabled={!enableSave}
          >
            Save & Test
          </Button>
        </div>
      </div>
    </>
  );
};

export default CustomerDataTable;
