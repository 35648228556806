import React, { useEffect } from "react";
import { Button } from "@mui/material";
import GoogleAnalyticsLogo from "../images/google-analytics4-logo.png";
import GoogleButton from "react-google-button";

const GoogleAnalyticsLoginModal = (props) => {
  const { GoogleLogo, handleGoogleSignIn, theme } = props;
  return (
    <>
      <div className="login-main-container d-flex-cen">
        <img className="patner-logo-img" src={GoogleAnalyticsLogo} alt="google logo" />
        <div className="modal-text-container d-flex-cen">
          <div className="primary-text d-flex-cen">
            Connect Your Google Analytics Data using Tercept
          </div>
          <div className="secondary-text">
            <span style={{ fontWeight: 600 }}>AdYogi </span>uses Tercept to
            replicate from this source securely. Data transfer is encrypted
            end-to-end and your credentials will never be made avaliable. Sign
            in to authorize data extraction from <span style={{ fontWeight: 600 }}>Google Analytics</span>. It should only
            take a few minutes.
          </div>
        </div>
        <div className="google-btn-container d-flex-cen">
          <GoogleButton
            onClick={handleGoogleSignIn}
          />
          
        </div>
      </div>
    </>
  );
};

export default GoogleAnalyticsLoginModal;
