import React, { useEffect, useState } from "react";
import "../styles/components/no-data.css";
import GoogleAds from "../images/google-ads.jpg";
import { useNavigate } from "react-router-dom";

const NoData = (props) => {
  const auth = localStorage.getItem("auth");
  const clientCustomerId = localStorage.getItem("clientID");
  const redirect_url = localStorage.getItem("redirect_url");
  const { setShowNoData, setShowDataTable } = props;
  const navigate = useNavigate();
  const [secRemaining, setSecRemaining] = useState(5);
  useEffect(() => {
    setTimeout(() => {
      setShowNoData(false);
      setShowDataTable(false);

      navigate(
        `/googleads?authorization=${auth}&clientcustomerid=${clientCustomerId}&redirect_uri=${redirect_url}`
      );
      return;
    }, 5000);
    setInterval(() => {
      setSecRemaining(secRemaining - 1);
    }, 1000);
  });
  return (
    <>
      <div className="no-data-main-container">
        <div className="no-data-google-ads-logo-container d-flex-cen">
          <img
            className="google-ads-img"
            src={GoogleAds}
            alt="google ads logo"
          />
          Google Ads
        </div>
        <div className="no-data-found-primary">Sorry No Data Found!</div>
        <div className="no-data-found-secondary">
          Redirecting to home page in {secRemaining}...
        </div>
      </div>
    </>
  );
};

export default NoData;
