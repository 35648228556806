import React from "react";
import "../styles/components/Auth-error-page.css"

const UnauthorizedPage=()=>{
    return(
        <>
        <div className="Error-page-main-container">
          <div className="Error-page-error-card d-flex-cen">
            <div className="error-page-primary-text">
                    Sorry your credentials didn't match! Please provide clientcustomerid, redirect_uri, authorization in the query param.
            </div>
          </div>
        </div>
        </>
    )
}

export default UnauthorizedPage