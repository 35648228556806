import React from "react";
import "./styles/globals.css";
import { Route, Routes } from "react-router-dom";
import GoogleAdsController from "./controllers/GoogleAdsController";
import GoogleAnalyticsController from "./controllers/GoogleAnalyticsController";
import AuthErrorPage from "./components/AuthErrorPage"
import Navbar from "./components/Navbar";
import UnauthorizedPage from "./components/UnauthorizedPage";

function App() {
  return (
    <>
    <Navbar/>
    <div style={{overflowX:"hidden"}}>
      <Routes>
        <Route
          exact={true}
          path="/googleads"
          element={<GoogleAdsController />}
        />
        <Route
          exact={true}
          path="/googleanalytics"
          element={<GoogleAnalyticsController />}
        />
        <Route
          exact={true}
          path="/googleanalytics/oauthcallback"
          element={<GoogleAnalyticsController />}
        />
        <Route
          exact={true}
          path="/oauthcallback"
          element={<GoogleAdsController />}
        />
        <Route
          exact={true}
          path="/error"
          element={<AuthErrorPage/>}
        />
        <Route
          exact={true}
          path="/unauthorized"
          element={<UnauthorizedPage/>}
        />
      </Routes>
    </div>
    </>
  );
}

export default App;
