import "../styles/layout/login.css";
import CustomerDataTable from "../components/CustomerDataTable";
import GoogleAnalyticsLoginModal from "../components/GoogleAnalyticsLoginModal";
import Skeleton from "@mui/material/Skeleton";
import { Stack } from "@mui/system";
import NoData from "../components/NoData";
import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

const GoogleAnalyticsLoginLayout = (props) => {
  const {
    handleGoogleSignIn,
    theme,
    GoogleLogo,
    response,
    handleSubmit,
    setResponse,
    showDataTable,
    Response,
    setCustomerId,
    customerId,
    access,
    showSkeleton,
    showNoData,
    setShowNoData,
    setShowDataTable,
    showSnackBar,
    setShowSnackBar,
  } = props;

  const [secRemaining, setSecRemaining] = useState(5);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackBar(false);
  };
  useEffect(() => {
    if(showSnackBar){
    setInterval(() => {
      setSecRemaining(secRemaining - 1);
    }, 1000);
  }
  },[showSnackBar,secRemaining]);
  return (
    <>
  
       {(!showDataTable && !showSkeleton && !showNoData) && (
        <GoogleAnalyticsLoginModal
          GoogleLogo={GoogleLogo}
          handleGoogleSignIn={handleGoogleSignIn}
          theme={theme}
        />
      )} 

      {(!showDataTable && showSkeleton && !showNoData) && (
        <div className="skeleton-main-container">
          <Skeleton  variant="rectangle" width={"35vw"} height={"4vw"} />
          <Stack spacing={1}>
            <Skeleton variant="round" width={"35vw"} height={"2vw"}/>
            <Skeleton variant="round" width={"35vw"} height={"2vw"}/>
            <Skeleton variant="round" width={"35vw"} height={"2vw"}/>
            <Skeleton variant="round" width={"35vw"} height={"2vw"}/>
            <Skeleton variant="round" width={"35vw"} height={"2vw"}/>
            <Skeleton variant="round" width={"35vw"} height={"2vw"}/>
            <Skeleton variant="round" width={"35vw"} height={"2vw"}/>
            <Skeleton variant="round" width={"35vw"} height={"2vw"}/>
            <Skeleton variant="round" width={"35vw"} height={"2vw"}/>
            <Skeleton variant="round" width={"35vw"} height={"2vw"}/>
            <Skeleton variant="round" width={"35vw"} height={"2vw"}/>
            <Skeleton variant="round" width={"35vw"} height={"2vw"}/>
          </Stack>
        </div>
      )}

      {(showDataTable && !showSkeleton && !showNoData) && (
        <CustomerDataTable
          response={response}
          setResponse={setResponse}
          handleSubmit={handleSubmit}
          theme={theme}
          Response={Response}
          setCustomerId={setCustomerId}
          customerId={customerId}
          access={access}
        />
      )}
      {
        showNoData &&
        <NoData
          setShowNoData={setShowNoData}
          setShowDataTable={setShowDataTable}
        />
      }
      <Snackbar open={showSnackBar} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Data Saved Successfully 
        </Alert>
      </Snackbar>
      <Snackbar 
        color="white"
        autoHideDuration={5000}
        anchorOrigin={ {vertical: 'top', horizontal: 'center' }}
        open={showSnackBar}
        onClose={handleClose}
        message={`Redirecting in ${secRemaining}..`}
      />


    </>
  );
};

export default GoogleAnalyticsLoginLayout;
