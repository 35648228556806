import React from "react";
import "../styles/components/Navbar.css"
import terceptLogo from "../images/tercept-logo.png"

const Navbar = ()=>{
    const handleTerceptNav=()=>{
        window.location.assign("https://analytics.tercept.com/")
    }
    return(
        <>
            <div className="navbar-main-container">
                <div onClick={handleTerceptNav} className="tercept-logo-container d-flex-cen">
                    <img
                        className="tercept-logo"
                        src={terceptLogo}
                        alt="tercept logo"
                    />
                </div>
            </div>
        </>
    )
}

export default Navbar